
















































import { Component, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationTableModel, TypeCertification } from '@/services/iResourceService';

import { Guid } from "guid-typescript";
import { SharedModule } from "@/feature/Shared/store";

@Component( {} )
export default class PgaCertificationsTableAccessibility extends mixins( SharedMixin )
{
	@Prop( { default: function () { return new Array<CertificationTableModel>() } } ) readonly CertificationsList!: Array<CertificationTableModel>;
	@Prop( { default: true } ) readonly isLoading!: boolean;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private headers: Array<any> =
		[
			{
				text: 'Vendor',
				allign: 'start',
				value: 'Vendor.BusinessName'
			},
			{
				text: 'Code',
				align: 'start',
				sortable: true,
				value: 'Code',
			},
			{
				text: 'Active',
				allign: 'start',
				value: 'IsActive'
			},
			{
				text: 'Description',
				allign: 'start',
				value: 'Description'
			},
			{
				text: 'Type',
				allign: 'start',
				value: 'Type'
			}
		];

	get listCertificatonsFiltered (): Array<CertificationTableModel>
	{
		if ( this.Search == "" || this.Search == null || this.Search == undefined )
		{
			return this.CertificationsList;
		}
		else
		{
			return this.CertificationsList.filter( i => objectMatchesText( this.Search, i ) );
		}
	}

	private Search = "";
	get search (): string
	{
		return this.Search;
	}
	set search ( value: string )
	{
		this.Search = value;
	}

	get loading (): boolean
	{
		return this.isLoading;
	}
	set loading ( value: boolean )
	{
		SharedModule.SET_ISLOADING( value );
	}

	private typeText ( type: TypeCertification ): string
	{
		switch ( type )
		{
			case TypeCertification.Exam:
				return "Exam";
			case TypeCertification.Certification:
				return "Certification";
			default:
				return "Error";
		}
	}

	private rowClicked ( id: Guid ): void
	{
		this.$router.push( { name: 'certificationdetails', params: { id: id.toString() } } );
	}

	private createAccessibility() : void
	{
		this.$emit('create-accessibility');
	}
}


function objectMatchesText ( text: string, obj )
{
	if ( typeof obj === "string" )
	{
		return obj.toLowerCase().includes( text.toLowerCase() );
	}

	return Object.values( obj ).some( val => objectMatchesText( text, val ) );
}
