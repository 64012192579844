










import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({
  components: {},
})
export default class ResourceDashboardPageIndex extends mixins(SharedMixin) {
  get ReportUrl(): string | undefined {
    return `https://app.powerbi.com/reportEmbed?reportId=${this.ReportId}&autoAuth=true&ctid=e6ad75bb-e91a-4c7d-a049-86122e5bd97f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"`;
  }

  get ReportId(): string | undefined {
    return  process.env.VUE_APP_POWERBI_REPORT_COMPETENCEMAPPING;
 }
}
