
































import Vue from "vue";
import { Component, Model, Prop } from "vue-property-decorator";
import { TypeCertification } from "@/services/iResourceService";
import { SharedModule } from "@/feature/Shared/store";

@Component( {} )
export default class PgaTypeCertification extends Vue
{
	@Prop( { type: String, default: "" } ) Title?: string;
	@Prop( { type: Boolean, default: false } ) ExplicitTooltip!: boolean;
	@Model( "", { type: Number } ) Model!: TypeCertification;

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}

	get color (): string
	{
		switch ( this.Model )
		{
			case TypeCertification.Exam:
				return "blue";
			case TypeCertification.Certification:
				return "purple";
			default:
				return "error";
		}
	}

	get icon (): string
	{
		switch ( this.Model )
		{
			case TypeCertification.Exam:
				return "mdi-text-box-check";
			case TypeCertification.Certification:
				return "mdi-certificate";
			default:
				return "mdi-triangle-outline";
		}
	}

	get tooltip (): string
	{
		switch ( this.Model )
		{
			case TypeCertification.Exam:
				return "Exam";
			case TypeCertification.Certification:
				return "Certification";
			default:
				return "Error";
		}
	}

	get titleDefined (): boolean
	{
		return this.Title != null;
	}

	get title (): string
	{
		return this.Title || "";
	}
}
