import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/infrastructure/store';
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import IResourceService, { UserIdDate } from '@/services/iResourceService';
import ResourceService from '@/services/ResourceService';

import { ListCertifications, CertificationModel, ExpertiseTableModel, UserAttainmentDateResource, VendorModel, CertificationTableModel, TypeCertification,
        CreateCertificationCommand, EditCertificationCommand, DeleteCertificationCommand, SelectUsersCertificationCommand,
        CreateCertificationResponse, EditCertificationResponse, DeleteCertificationResponse, SelectUsersCertificationResponse } from '@/services/iResourceService';

import { SharedModule } from '@/feature/Shared/store';

export interface CertificationState
{
    CertificationTable: ListCertifications;
    CertificationDetails: CertificationModel;
    CertificationCreate: CreateCertificationModel;
    CertificationEdit: EditCertificationModel;
    CertificationDelete: DeleteCertificationModel;
    CertificationSelectUsers: SelectUsersCertificationModel;

    ExpertisesDropDown: Array<ExpertiseTableModel>;
    VendorsDropDown: Array<VendorModel>;
}

export interface CreateCertificationModel
{
    Command: CreateCertificationCommand | null;
    Response: CreateCertificationResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditCertificationModel
{
    ViewModel: CertificationModel | null;
    Command: EditCertificationCommand | null;
    Response: EditCertificationResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteCertificationModel
{
    Id: Guid;
    Response: DeleteCertificationResponse | null;
}

export interface SelectUsersCertificationModel
{
    Command: SelectUsersCertificationCommand | null;
    Response: SelectUsersCertificationResponse | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'CertificationModule', })
class CertificationsStore extends VuexModule implements CertificationState
{
    private _resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);

    public CertificationTable: ListCertifications =
    {
        Certifications: new Array<CertificationModel>(),
    };

    public CertificationDetails: CertificationModel =
    {
        Id: Guid.createEmpty(),
        TenantId: Guid.createEmpty(),
        Code: '',
        Description: '',
        IsActive: true,
        Type: 0,
        Vendor:
        {
            Id: Guid.createEmpty(),
            Code: '',
            BusinessName: '',
            Status: 0,
        },
        ModifiedBy:
        {
            Id: Guid.createEmpty(),
            FullName: '',
        },
        ModifiedDate: new Date(),
        Expertises: new Array<ExpertiseTableModel>(),
        Users: new Array<UserAttainmentDateResource>(),
    }

    public CertificationCreate: CreateCertificationModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };
    
    public CertificationEdit: EditCertificationModel =
    {
        ViewModel: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public CertificationDelete: DeleteCertificationModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    };

    public CertificationSelectUsers: SelectUsersCertificationModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };


    public ExpertisesDropDown: Array<ExpertiseTableModel> = new Array<ExpertiseTableModel>();
    public VendorsDropDown: Array<VendorModel> = new Array<VendorModel>();

    // DropDown Expertises
    @Action({ rawError: true })
    public async GetExpertisesDropDown()
    {
        const expertises = await this._resourceService.GetAllExpertises();
        this.SET_EXPERTISES_DROPDOWN(expertises.Expertises);
    }
    @Mutation
    public SET_EXPERTISES_DROPDOWN(expertises: Array<ExpertiseTableModel>)
    {
        this.ExpertisesDropDown = expertises;
    }

    // DropDown Vendors
    @Action({ rawError: true })
    public async GetVendorsDropDown()
    {
        const vendors = await this._resourceService.GetAllVendorsDropdown();
        this.SET_VENDORS_DROPDOWN(vendors.Vendors);
    }
    @Mutation
    public SET_VENDORS_DROPDOWN(vendors: Array<VendorModel>)
    {
        this.VendorsDropDown = vendors;
    }


    // List Certifications
    @Action({ rawError: true })
    public async GetAllCertifications()
    {
        const certifications = await this._resourceService.GetAllCertifications();
        this.SET_CERTIFICATIONS(certifications);
    }
    @Mutation
    public SET_CERTIFICATIONS(certifications: ListCertifications)
    {
        this.CertificationTable = certifications;
    }

    // Details Certification
    @Action({ rawError: true })
    public async GetCertificationDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const certification: CertificationModel = await this._resourceService.GetCertificationById(id);
        this.SET_CERTIFICATION_DETAILS(certification);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropCertificationDetails()
    {
        const resetCertificationDetails: CertificationModel =
        {
            Id: Guid.createEmpty(),
            TenantId: Guid.createEmpty(),
            Code: '',
            Description: '',
            IsActive: true,
            Type: 0,
            Vendor:
            {
                Id: Guid.createEmpty(),
                Code: '',
                BusinessName: '',
                Status: 0,
            },
            ModifiedBy:
            {
                Id: Guid.createEmpty(),
                FullName: '',
            },
            ModifiedDate: new Date(),
            Expertises: new Array<ExpertiseTableModel>(),
            Users: new Array<UserAttainmentDateResource>(),
        };
        this.SET_CERTIFICATION_DETAILS(resetCertificationDetails);
    }
    @Mutation
    public SET_CERTIFICATION_DETAILS(certification: CertificationModel)
    {
        this.CertificationDetails = certification;
    }

    // Create Certification
    @Action({ rawError: true })
    public async CreateCertification(): Promise<CreateCertificationResponse>
    {
        try
        {
            let response: CreateCertificationResponse = { Id: Guid.createEmpty() };

            SharedModule.SET_ISLOADING(true);
            if(this.CertificationCreate.Command)
                response = await this._resourceService.CreateCertification(this.CertificationCreate.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_CERTIFICATION_CREATE_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public InitCertificationCreate()
    {
        const command: CreateCertificationCommand =
        {
            Code: '',
            Description: '',
            IsActive: true,
            Type: 1,
            VendorId: null,
            ExpertiseIds: new Array<Guid>(),
        }

        this.SET_CERTIFICATION_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropExpertiseCreate()
    {
        this.SET_CERTIFICATION_CREATE(null);
    }
    @Mutation
    public SET_CERTIFICATION_CREATE(command: CreateCertificationCommand | null)
    {
        this.CertificationCreate.Command = command;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_CODE(Code: string)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.Code = Code;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_DESCRIPTION(description: string)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.Description = description;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_ISACTIVE(isActive: boolean)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.IsActive = isActive;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_TYPE(type: TypeCertification)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.Type = type;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_VENDORID(vendorId: Guid)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.VendorId = vendorId;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_EXPERTISEIDS(expertiseIds: Array<Guid>)
    {
        if(this.CertificationCreate.Command)
            this.CertificationCreate.Command.ExpertiseIds = expertiseIds;
    }
    @Mutation
    public SET_CERTIFICATION_CREATE_ERRORS(errs: Map<string, Array<string>>)
    {
        this.CertificationCreate.Errors = errs;
    }

    // Edit Certification
    @Action({ rawError: true })
    public async EditCertification()
    {
        try
        {
            let response: EditCertificationResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if(this.CertificationEdit.Command)
                response = await this._resourceService.EditCertification(this.CertificationEdit.Command.Id, this.CertificationEdit.Command);

            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch( error )
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_CERTIFICATION_EDIT_ERRORS(errs);
            
            throw errs;
        }
    }
    @Action({ rawError: true})
    public async GetCertificationToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_CERTIFICATION_EDIT_COMMAND(null);
        this.SET_CERTIFICATION_EDIT_VIEWMODEL(null);

        const certification: CertificationModel = await this._resourceService.GetCertificationById(id);

        this.SET_CERTIFICATION_EDIT_VIEWMODEL(certification);
        this.InitCertificationEdit();

        this.SET_CERTIFICATION_EDIT_ID(certification.Id);
        this.SET_CERTIFICATION_EDIT_CODE(certification.Code);
        this.SET_CERTIFICATION_EDIT_DESCRIPTION(certification.Description);
        this.SET_CERTIFICATION_EDIT_ISACTIVE(certification.IsActive);
        this.SET_CERTIFICATION_EDIT_TYPE(certification.Type);
        this.SET_CERTIFICATION_EDIT_VENDORID(certification.Vendor.Id as Guid);
        this.SET_CERTIFICATION_EDIT_EXPERTISEIDS(certification.Expertises?.map(e => e.Id) ?? new Array<string>());

        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitCertificationEdit()
    {
        const command: EditCertificationCommand =
        {
            Id: Guid.createEmpty(),
            Code: '',
            Description: '',
            IsActive: true,
            Type: 0,
            VendorId: Guid.createEmpty(),
            ExpertiseIds: new Array<Guid>(),
        }

        this.SET_CERTIFICATION_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_COMMAND(command: EditCertificationCommand | null)
    {
        this.CertificationEdit.Command = command;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_VIEWMODEL(viewModel: CertificationModel | null)
    {
        this.CertificationEdit.ViewModel = viewModel;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_ID(id: Guid)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.Id = id;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_CODE(code: string)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.Code = code;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_DESCRIPTION(description: string)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.Description = description;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_ISACTIVE(isActive: boolean)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.IsActive = isActive;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_TYPE(type: TypeCertification)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.Type = type;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_VENDORID(vendorId: Guid)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.VendorId = vendorId;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_EXPERTISEIDS(expertises: Array<Guid>)
    {
        if(this.CertificationEdit.Command)
            this.CertificationEdit.Command.ExpertiseIds = expertises;
    }
    @Mutation
    public SET_CERTIFICATION_EDIT_ERRORS(errs: Map<string, Array<string>>)
    {
        this.CertificationEdit.Errors = errs;
    }
    
    // Delete Certification
    @Action({ rawError: true })
    public async DeleteCertification(id: Guid): Promise<DeleteCertificationResponse>
    {
        try {
            let response: DeleteCertificationResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_CERTIFICATION_DELETE(id);

            if (this.CertificationDelete)
                response = await this._resourceService.DeleteCertification(this.CertificationDelete.Id);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            
            const errs = parseErrors(error);
            const message = errs.get('id')?.map(e => e = `- ${e}`).join(" <br> ") ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE(message);
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            
            throw error;
        }
    }
    @Mutation
    public SET_CERTIFICATION_DELETE(id: Guid)
    {
        this.CertificationDelete.Id = id;
    }

    // Select Users for Certification
    @Action({ rawError: true })
    public async SelectUsersCertification(id: Guid): Promise<SelectUsersCertificationResponse>
    {
        try {
            let response: SelectUsersCertificationResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);

            if (this.CertificationSelectUsers.Command)
                response = await this._resourceService.SelectUsersCertification(id, this.CertificationSelectUsers.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_CERTIFICATION_SELECTUSERS_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public InitCertificationSelectUsers()
    {
        const command: SelectUsersCertificationCommand =
        {
            Users: new Array<UserIdDate>(),
        }

        const userIdDates: Array<UserIdDate> = new Array<UserIdDate>();
        this.CertificationDetails.Users.forEach(u => userIdDates.push({ UserId: u.Id, AttainmentDate: u.AttainmentDate, ExpireDate: u.ExpireDate, }));

        this.SET_CERTIFICATION_SELECTUSERS_COMMAND(command);
        this.SET_CERTIFICATION_SELECTUSERS_USERS(userIdDates);
    }
    @Mutation
    public SET_CERTIFICATION_SELECTUSERS_COMMAND(command: SelectUsersCertificationCommand)
    {
        this.CertificationSelectUsers.Command = command;
    }
    @Mutation
    public SET_CERTIFICATION_SELECTUSERS_USERS(userIds: Array<UserIdDate>)
    {
        if(this.CertificationSelectUsers.Command)
            this.CertificationSelectUsers.Command.Users = userIds;
    }
    @Mutation
    public SET_CERTIFICATION_SELECTUSERS_ATTAINMENT(userIdDate: UserIdDate)
    {
        if(this.CertificationSelectUsers.Command)
            this.CertificationSelectUsers.Command.Users.forEach(ud =>
                {
                    if(ud.UserId == userIdDate.UserId)
                        ud.AttainmentDate = userIdDate.AttainmentDate;
                });
    }
    @Mutation
    public SET_CERTIFICATION_SELECTUSERS_EXPIRE(userIdDate: UserIdDate)
    {
        if(this.CertificationSelectUsers.Command)
            this.CertificationSelectUsers.Command.Users.forEach(ud =>
                {
                    if(ud.UserId == userIdDate.UserId)
                        ud.ExpireDate = userIdDate.ExpireDate;
                });
    }
    @Mutation
    public SET_CERTIFICATION_SELECTUSERS_ERRORS(errors: Map<string, Array<string>>)
    {
        this.CertificationSelectUsers.Errors = new Map<string, Array<string>>(errors);
    }
}

function parseErrors(error: any): Map<string, Array<string>>
    {
        const errs = new Map<string, Array<string>>();
        for (const [key, value] of Object.entries(error.response.data)) {
            const messages = value as Array<string>;
            errs.set(key, messages);
        }
        return errs;
    }

export const CertificationStore = getModule(CertificationsStore);