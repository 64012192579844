


























import { Component } from "vue-property-decorator";
import { CertificationStore } from "./store";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import PgaCertificationsTable from './Components/PgaCertificationsTable.vue';
import PgaCertificationsTableAccessibility from './Accessibility/PgaCertificationsTableAccessibility.vue';
import { CertificationTableModel } from '@/services/iResourceService';

@Component( {
	components: {
		PgaCertificationsTable,
		PgaCertificationsTableAccessibility,
	}
} )
export default class CertificationsPageIndex extends mixins( SharedMixin )
{
	private isLoading = true;

	get CertificationsList (): Array<CertificationTableModel>
	{
		return CertificationStore.CertificationTable.Certifications;
	}

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}

	async mounted (): Promise<void>
	{
		this.isLoading = true;
		await CertificationStore.GetAllCertifications();
		this.isLoading = false;
	}
}
