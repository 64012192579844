


























import { Component, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationTableModel } from '@/services/iResourceService';

import PgaTypeCertification from "./PgaTypeCertification.vue";

@Component( {
	components: { PgaTypeCertification, },
} )
export default class PgaCertificationsTable extends mixins( SharedMixin )
{
	@Prop( { default: function () { return new Array<CertificationTableModel>() } } ) readonly CertificationsList!: Array<CertificationTableModel>;
	@Prop( { default: true } ) readonly isLoading!: boolean;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers: Array<any> =
		[
			{
				text: 'Vendor',
				allign: 'start',
				value: 'Vendor.BusinessName',
				keySearch: "ve",
			},
			{
				text: 'Code',
				align: 'start',
				sortable: true,
				value: 'Code',
				keySearch: "co",
			},
			{
				text: 'Active',
				allign: 'start',
				value: 'IsActive'
			},
			{
				text: 'Description',
				allign: 'start',
				value: 'Description',
				keySearch: "de",
			},
			{
				text: 'Type',
				allign: 'start',
				value: 'Type'
			}
		];

	rowClicked ( item: any ): void
	{
		const id = item.Id;
		this.$router.push( { name: 'certificationdetails', params: { id } } );
	}
}
